<template>
    <BT-Blade-Items
        addBladeName="order-invoicing-action"
        bladeName="order-invoicing-actions"
        :bladesData="bladesData"
        canAdd
        :canDelete="false"
        :canEdit="false"
        canSearchLocal
        :dependantBlades="[{ bladeName: 'order-invoicing-action' }]"
        :headers="[
            { text: 'Name', value: 'functionName', title: 1, searchable: true },
            { text: 'Started On', value: 'startedOn', textFilter: 'toShortDateAndTime', subtitle: 1, searchable: true },
            { text: 'Status', value: 'isSuccess', display: true, subtitle: 2 }]"
        hideActions
        navigation="order-invoicing-actions"
        title="Invoice Actions"
        useServerPagination>
        <template v-slot:isSuccess="{ item }">
            <span v-if="!isMinDate(item.finishedOn)">{{ item.isSuccess ? 'Succeeded' : 'Failed' }}</span>
            <v-progress-circular v-else indeterminate size="16" />
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Ordering-Actions-Blade',
    data: function() {
        return {
            bladeData: null
        }
    },
    props: {
        bladesData: {
            type: Object,
            default: null
        },
    },
    methods: {
        
    }
}
</script>